import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import loadable from '@loadable/component';

import S from './Styles';
import useSelector from '../redux/typedHooks';
import { LoaderContent } from '../pages/LoaderPage';
import { DocumentTypeSwitch } from './DocumentTypeSwitch';
import { NotFound } from '../loadable-elements/NotFound';

const MyNafHomePage = loadable(() => import('../pages/MyNaf'), {
  resolveComponent: (components) => components.MyNafHomePage,
});

export const bilPath = ':licenseSlug';
const Vehicle = loadable(() => import('../pages/vehicle'), {
  resolveComponent: (components) => components.Vehicle,
});

export const directDebetReceiptSlug = 'avtalegiro-kvittering';
const DirectDebetReceipt = loadable(() => import('../pages/DirectDebetReceipt'), {
  resolveComponent: (components) => components.DirectDebetReceipt,
});

export const myTravelsSlug = 'mine-reiser';
const MyTravels = loadable(() => import('../pages/myTravels'), {
  resolveComponent: (components) => components.MyTravels,
});

export const myProfileSlug = 'min-profil';
const MyProfile = loadable(() => import('../pages/MyProfile'), {
  resolveComponent: (components) => components.MyProfile,
});

export const myMembershipSlug = 'mitt-medlemskap';
const MyMembership = loadable(() => import('../pages/MyMembership'), {
  resolveComponent: (components) => components.MyMembership,
});

export const activateSlug = 'circle-k-aktivert';
const ActivateCircleK = loadable(() => import('../pages/ActivateCircleK'), {
  resolveComponent: (components) => components.ActivateCircleK,
});

export const membershipCertificateSlug = 'medlemsbevis';
const MembershipCertificate = loadable(() => import('../pages/MembershipCertificate'), {
  resolveComponent: (components) => components.MembershipCertificate,
});

export const myDocumentsSlug = 'dokumenter';
const MyDocuments = loadable(() => import('../pages/MyDocuments'), {
  resolveComponent: (components) => components.MyDocuments,
});

export const vehiclesSlug = 'mine-kjøretøy';
const MyVehicles = loadable(() => import('../pages/myVehicles'), {
  resolveComponent: (components) => components.MyVehicles,
});

export const messagesSlug = 'mine-meldinger';
const MyMessages = loadable(() => import('../pages/MyMessages'), {
  resolveComponent: (components) => components.MyMessages,
});

export const terminateMembershipSlug = 'utmelding';
const TerminateMembership = loadable(() => import('../pages/downgrade/TerminateMembership/TerminateMembership'), {
  resolveComponent: (components) => components.TerminateMembership,
});

export const downgradeRoadsideAssistanceSlug = 'utmelding-veihjelp';
const DowngradeRoadsideAssistance = loadable(
  () => import('../pages/downgrade/DowngradeRoadsideAssistance/DowngradeRoadsideAssistance'),
  {
    resolveComponent: (components) => components.DowngradeRoadsideAssistance,
  },
);

export const downgradeHouseholdMembersSlug = 'utmelding-husstandsmedlemmer';
const DowngradeHouseholdMembers = loadable(
  () => import('../pages/downgrade/DowngradeHouseholdMembers/DowngradeHouseholdMembers'),
  {
    resolveComponent: (components) => components.DowngradeHouseholdMembers,
  },
);

export const downgradeNafMCSlug = 'utmelding-naf-mc';
const DowngradeNafMC = loadable(() => import('../pages/downgrade/DowngradeNafMC/DowngradeNafMC'), {
  resolveComponent: (components) => components.DowngradeNafMC,
});

interface MyProfileRouterProps {
  match: any;
}

const MyVehicleRoute = ({
  match: {
    params: { licenseSlug },
  },
}: {
  match: { params: { licenseSlug: string } };
}): ReactElement => <Vehicle licenseSlug={licenseSlug} />;

const DocumentRoute = ({
  match: {
    params: { slug },
  },
}: {
  match: { params: { slug: string } };
}): ReactElement => <DocumentTypeSwitch slug={slug} omitFromLocationValidation />;

export const MyProfileRouter = ({ match }: MyProfileRouterProps) => {
  const { isLoading } = useAuth0();
  if (isLoading) return <LoaderContent />;

  return <MyProfileRouterWithAuth match={match} />;
};

const MyProfileRouterWithAuth = withAuthenticationRequired(({ match: myProfileMatch }: MyProfileRouterProps) => {
  const {
    myMembership: isMyMembershipEnabled,
    myMessages: isMyMessagesEnabled,
    myVehicles: isMyVehiclesEnabled,
    myDocuments: isMyDocumentsEnabled,
    myProfile: isMyProfileEnabled,
    myTravels: isMyTravelsEnabled,
    downgrade: isDowngradeEnabled,
  } = useSelector((state) => state.application.toggledFeatures);

  return (
    <S.Wrapper>
      <Switch>
        <Route exact path={`${myProfileMatch.path}`} component={MyNafHomePage} />
        {isMyProfileEnabled && <Route path={`${myProfileMatch.path}/${myProfileSlug}`} component={MyProfile} />}
        <Route path={`${myProfileMatch.path}/${terminateMembershipSlug}`} component={TerminateMembership} />
        {isDowngradeEnabled && (
          <Route
            path={`${myProfileMatch.path}/${downgradeRoadsideAssistanceSlug}`}
            component={DowngradeRoadsideAssistance}
          />
        )}
        {isDowngradeEnabled && (
          <Route
            path={`${myProfileMatch.path}/${downgradeHouseholdMembersSlug}`}
            component={DowngradeHouseholdMembers}
          />
        )}
        {isDowngradeEnabled && (
          <Route path={`${myProfileMatch.path}/${downgradeNafMCSlug}`} component={DowngradeNafMC} />
        )}
        <Route path={`${myProfileMatch.path}/${membershipCertificateSlug}`} component={MembershipCertificate} />
        {isMyVehiclesEnabled && <Route exact path={`${myProfileMatch.path}/${vehiclesSlug}`} component={MyVehicles} />}
        {isMyVehiclesEnabled && (
          <Route exact path={`${myProfileMatch.path}/${vehiclesSlug}/${bilPath}`} component={MyVehicleRoute} />
        )}
        {isMyDocumentsEnabled && <Route path={`${myProfileMatch.path}/${myDocumentsSlug}`} component={MyDocuments} />}
        {isMyMessagesEnabled && <Route path={`${myProfileMatch.path}/${messagesSlug}`} component={MyMessages} />}
        {isMyMembershipEnabled && (
          <Route path={`${myProfileMatch.path}/${directDebetReceiptSlug}`} component={DirectDebetReceipt} />
        )}
        {isMyTravelsEnabled && <Route path={`${myProfileMatch.path}/${myTravelsSlug}`} component={MyTravels} />}
        {isMyMembershipEnabled && (
          <>
            <Route path={`${myProfileMatch.path}/${myMembershipSlug}/${activateSlug}`} component={ActivateCircleK} />
            <Route exact path={`${myProfileMatch.path}/${myMembershipSlug}`} component={MyMembership} />
          </>
        )}
        <Route path={`${myProfileMatch.path}/:slug`} component={DocumentRoute} />
        <Route path={`${myProfileMatch.path}/*/:slug`} component={DocumentRoute} />
        <Route component={NotFound} />
      </Switch>
    </S.Wrapper>
  );
});
